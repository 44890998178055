var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-row',{staticClass:"px-0",attrs:{"no-gutters":""}},_vm._l((_vm.activityTypeGroups),function(group,index){return _c('b-col',{key:index,staticClass:"p-1",attrs:{"cols":"6"}},[_c('router-link',{staticClass:"activity_icon br-1 d-flex flex-column justify-content-center align-items-center",style:({
            'background-color': group.iconBGColor,
            'color': group.iconStrokeColor
          }),attrs:{"to":{ name: _vm.routeName , params: { groupCode: group.code } }}},[_c('div',{staticClass:"activity_icon_path mt-2",style:({
            'mask-image': 'url(' + require('@/assets/icons/activities/' + (group.iconPath ? group.iconPath : 'tronconneuse') + '.svg') + ')',
            'background-color': group.iconStrokeColor,
          })}),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(group.designation)+" ")])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }