<template>
  <b-container>
    <b-row no-gutters class="px-0">
      <b-col
        cols="6"
        v-for="(group, index) in activityTypeGroups"
        :key="index"
        class="p-1">
        <router-link
          :to="{ name: routeName , params: { groupCode: group.code } }"
          :style="{
              'background-color': group.iconBGColor,
              'color': group.iconStrokeColor
            }"
          class="activity_icon br-1 d-flex flex-column justify-content-center align-items-center"
        >
          <div
            class="activity_icon_path mt-2"
            :style="{
              'mask-image': 'url(' + require('@/assets/icons/activities/' + (group.iconPath ? group.iconPath : 'tronconneuse') + '.svg') + ')',
              'background-color': group.iconStrokeColor,
            }"
          ></div>
          <div class="title">
            {{ group.designation }}
          </div>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  computed: {
    ...mapGetters({
      activityTypeGroups: "activityTypeGroups"
    }),
    routeName() {
      if (this.$route.name.includes("tour")) {
        return "tour.new.edit";
      } 
      if (this.$route.name.includes("cropfield")) {
        return "cropfield.activity.new";
      }
      return "activity.new.edit";
    },
  },
  async mounted() {
    this.loadOn();

    await this.$store.dispatch("getAllActivityTypeGroups");
    this.loadOff();
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.activity_icon {
  height: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
  overflow: hidden;
  padding: 0.25rem;
}

// hover effect
.activity_icon::before {
  content: "";
  background: rgba(0, 0, 0, 0.10);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.activity_icon:hover::before {
  opacity: 1;
}

.activity_icon_path {
  mask-size: cover;
  width: 50px;
  height: 50px;
}

.title {
  display: flex;
  align-items: center;
  height: 40px;
}
</style>
